import React from 'react';
import './App.css';
import Card from './Components/Card/Card';


const app = () => {
  return (
    <div>
      <Card />
    </div>
  );
}

export default app;
