import React from 'react';
import './Input.css';

const Input = () => {
    return (
        <div className="cont-input">
            <div className="cont-input-style">
                <input placeholder='E-mail' className="cont-style-input"></input>
                <input placeholder='Senha' className="cont-style-input"></input>
            </div>
        </div>
    )
}

export default Input;
