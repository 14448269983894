import React from 'react';
import './Button.css';

interface ButtonProps {
    title: string;
}

const Button: React.FC<ButtonProps> = ({ title }) => (
    <div className="cont-btn">
        <button className="btn" title={title}>
            {title}
        </button>
    </div>
);
export default Button;
