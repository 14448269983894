import Button from "../Button/Button";
import Input from "../Input/Input";
import './Card.css';


const Card = () => {
    return (
        <div className="cont-display">
            <div className="cont-img">
            </div>
            <div className="card">
                <h2 className="h2">Faça login <br /> para conhecer nosso espaço</h2>
                <form>
                    <div className="form-group">
                        <Input />
                        <Button title="Entrar" />
                    </div>
                </form>
            </div>
        </div>

    )
}

export default Card;